<template>
  <v-row class="fill-height">
    <v-col cols="12" align-self="center" align="center">
      <v-card flat class="mx-3">
        <v-card-title class="text-h4 grey--text text--darken-3">
          Calendar
          <v-spacer></v-spacer>
          <add-event></add-event>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col align-self="center" align="center" v-if="getCalenderItems.length !== 0">
      <v-card class="mx-3" flat height="100%">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="calendarItems"
            :event-color="getEventColor"
            :type="type"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="showEvent"
          >
          </v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-card>
    </v-col>
    <v-col cols="12" v-else align-self="center" align="center">
      <v-btn @click="$store.dispatch('pullCalendarItem')" color="primary"> Load Calendar </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import addEvent from "@/components/scheduling/addEvent.vue";

export default {
  name: "scheduling",
  data() {
    return {
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      focus: "",
      CLIENT_ID:
        "342515181178-b9e1evh2hkilidlp1iv4fo0rcmnh0u1i.apps.googleusercontent.com",
      API_KEY: "AIzaSyBVz6MWvfr9e7Ua20qLaK8bFF7AfVK-5Kk",
      DISCOVERY_DOCS:
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      SCOPES: "https://www.googleapis.com/auth/calendar.events",
      pullCalendar: false,
    };
  },
  components: {
    addEvent,
  },
  computed: {
    ...mapGetters(["getApi", "getCalenderItems", "getShowCalendar"]),
    calendarItems(){
      let calendarData = []
      this.getCalenderItems.forEach(item => {
        console.log(item)
        let data = {
          name: item.name,
          timed: !item.allDay,
          allDay: item.allDay,
          location: item.location,
          organizer: item.organizer,
          startTime: item.startTime,
          endTime: item.endTime,
          parentEventId: item.parentEventId,
          timeZone: item.timeZone,
          recurrence: item.recurrence,
          description: item.description,
          recurrenceDays: item.recurrenceDays,
          startDate: item.startDate,
          endDate: item.endDate,
          id: item.id,
          attendees: item.attendees
        } 
        if(item.startTime === undefined){
          data['start'] = new Date(item.startDate +'T00:00:00')
          data['end'] = new Date(item.endDate +'T00:00:00')
          data['color'] = 'primary'
        }else{
          data['start'] = new Date(item.startDate + 'T' + item.startTime)
          data['end'] = new Date(item.endDate + 'T' + item.endTime)
          data['color'] = 'secondary'
        } 
        calendarData.push(data)
      })
      return calendarData
    }
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday({ date }) {
      this.focus = date;
      this.type = "day";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    getCalenderData() {
      try {
        if (this.getCalenderItems.length === 0) {
          this.$store.dispatch('pullCalendarItem')
        } else {
          console.log("Please Log in to Continue");
        }
      } catch (e) {
        console.log("Something is wrong", e.message);
      }
    },
    showEvent({ event }) {
      console.log("The Clicked Event", event);
      this.$store.commit("setViewEvent", true)
      this.$store.commit("setEvent", event)
    },
  },
};
</script>